<template>
  <div>
    <Toast ref="mensajeToast"></Toast>
    <Alerts ref="alert"></Alerts>
    <Confirm ref="confirm"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Estudiantes Inscritos</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <ul class="horizontal-bars type-2">
                  <div class="progress-group" v-for="alumnos in alumnos_carreras">
                    <div class="progress-group-header">
                      <CIcon name="cil-globe-alt" class="progress-group-icon"/>
                      <span class="title">{{ alumnos.carrera }}</span>
                      <span class="ml-auto font-weight-bold">
                        {{ alumnos.total }} <span class="text-muted small">({{ alumnos.porcentaje }}%)</span>
                      </span>
                    </div>
                    <div class="progress-group-bars">
                      <CProgress
                          class="progress-xs"
                          :value="alumnos.avance"
                          color="success"
                      />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CCard v-if="hasRole('reportesfacturacion')">
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Facturación
              <small class="small text-muted">({{ periodo }})</small>
            </h4>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <CButtonGroup class="float-right mr-3">
              <CButton
                  color="outline-secondary"
                  v-for="(value, key) in ['Día', 'Mes', 'Año']"
                  :key="key"
                  class="mx-0"
                  :pressed="value === selected ? true : false"
                  @click="selected = value;actualizaFacturacion()"
              >
                {{ value }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow class="text-center">
          <template v-for="factura in facturacion">
            <CCol md sm="12" class="mb-sm-2 mb-0">
              <div class="text-muted">{{ factura.tipo }}</div>
              <strong>Bs {{ factura.monto_total }}({{ factura.porcentaje }}%)</strong>
              <CProgress
                  class="progress-xs mt-2"
                  :precision="1"
                  color="success"
                  :value="Math.ceil(factura.porcentaje)"
              />
            </CCol>
          </template>
        </CRow>
      </CCardFooter>
    </CCard>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="darkModal"
        size="sm"
    >
      <div class="row">
        <div class="col-12">
          <select v-model="sedeSeleccionada" class="form-control  form-control-lg bg-success text-white"
                  @change="seleccionarSede()" id="sedeSeleccionada2">
            <option v-for="sede in sedes" :key="sede.value" :value=sede.value
                    v-bind:selected="sedeSeleccionada==sede.value ? true : false">
              {{ sede.label }}
            </option>
          </select>
        </div>
        <div class="col-12 text-center pt-3">
          <button type="button" class="btn btn-outline-primary" @click="mantenerSeleccionarSede()">
            Mantener la sede actual
          </button>
        </div>
      </div>

      <template #header>
        <h5 class="modal-title">
          Seleccionar Sede
        </h5>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import Confirm from "@/views/notifications/Confirm";

export default {
  name: 'Dashboard',
  components: {
    Confirm,
    Alerts,
    Toast,
  },
  data() {
    return {
      darkModal: false,
      sedeSeleccionada: 1,
      sedes: [],
      selected: 'Mes',
      alumnos_carreras: [],
      facturacion: [],
      periodo: "",
    }
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    actualizaFacturacion() {
      let self = this
      let modalPdf = this;
      axios.get(self.$apiAdress + '/api/dashboard/' + localStorage.sedeSeleccionada + '/' + this.selected + '/facturacion?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            self.facturacion = response.data.facturacion;
            self.periodo = response.data.periodo;
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    seleccionarSede() {
      if(parseInt(this.sedeSeleccionada)>0) {
        localStorage.eligesede= false;
        localStorage.sedeSeleccionada = this.sedeSeleccionada;
        this.darkModal = false
        this.$root.$emit('sedeSeleccionada', this.sedeSeleccionada);
        window.location.reload();
      }
    },
    mantenerSeleccionarSede() {
      if(parseInt(this.sedeSeleccionada)>0) {
        localStorage.eligesede= false;
        localStorage.sedeSeleccionada = this.sedeSeleccionada;
        this.darkModal = false

      }
    },
  },
  mounted: function () {
    let modalPdf = this;
    if (localStorage.getItem("api_token") === null) {
      this.$router.push({path: '/login'});
    }
    if (localStorage.sedeSeleccionada) {
      this.sedeSeleccionada = localStorage.sedeSeleccionada;
    } else {
      localStorage.sedeSeleccionada = this.sedeSeleccionada;
    }
    axios.get(modalPdf.$apiAdress + '/api/dashboard/' + localStorage.sedeSeleccionada + '/estudiantesporcarrera?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.alumnos_carreras = response.data.alumnos_carreras;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    modalPdf.actualizaFacturacion();
    if (localStorage.eligesede==='true') {
      axios.post(modalPdf.$apiAdress + '/api/sede/listar' + '?token=' + localStorage.getItem("api_token"), {
        _method: 'GET'
      })
          .then(function (response) {
            modalPdf.sedes = response.data;
            modalPdf.darkModal = true
          })
          .catch(function (error) {
            console.log(error);
            modalPdf.$router.push({path: '/login'});
          });

    }
  }
}
</script>